import * as i0 from '@angular/core';
import { InjectionToken, inject, ErrorHandler, Injectable, HostListener, PLATFORM_ID, DestroyRef, ViewContainerRef, ChangeDetectorRef, TemplateRef, Directive, Input, Renderer2, Optional, SkipSelf, NgModule, makeEnvironmentProviders, ENVIRONMENT_INITIALIZER } from '@angular/core';
import { isPlatformServer, DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, debounceTime, animationFrameScheduler, switchMap, from, catchError, EMPTY, tap, mergeMap } from 'rxjs';
const LAZY_ELEMENT_CONFIGS = new InjectionToken('LAZY_ELEMENT_CONFIGS');
const LAZY_ELEMENT_ROOT_OPTIONS = new InjectionToken('LAZY_ELEMENT_ROOT_OPTIONS');
const LAZY_ELEMENT_ROOT_GUARD = new InjectionToken('LAZY_ELEMENT_ROOT_GUARD');
const LAZY_ELEMENTS_REGISTRY = new InjectionToken('LAZY_ELEMENTS_REGISTRY', {
  providedIn: 'root',
  factory: () => new Map()
});
let policy;
function getPolicy() {
  if (policy === undefined) {
    policy = null;
    if (typeof window !== 'undefined') {
      const ttWindow = window;
      if (ttWindow.trustedTypes !== undefined) {
        policy = ttWindow.trustedTypes.createPolicy('angular-extensions#elements', {
          createScriptURL: url => url
        });
      }
    }
  }
  return policy;
}
const LOG_PREFIX$2 = '@angular-extensions/elements';
class LazyElementsLoaderService {
  static controller = new AbortController();
  #errorHandler = inject(ErrorHandler);
  #registry = inject(LAZY_ELEMENTS_REGISTRY);
  options = inject(LAZY_ELEMENT_ROOT_OPTIONS, {
    optional: true
  }) ?? {};
  configs = [];
  ngOnDestroy() {
    LazyElementsLoaderService.controller?.abort();
    LazyElementsLoaderService.controller = null;
  }
  addConfigs(newConfigs) {
    newConfigs.forEach(newConfig => {
      const existingConfig = this.getElementConfig(newConfig.tag);
      if (existingConfig) {
        ngDevMode && console.warn(`${LOG_PREFIX$2} - ElementConfig for tag '${newConfig.tag}' was previously added, it will not be added multiple times, continue...`);
      } else {
        newConfig.isAdded = true;
        this.configs.push(newConfig);
        const shouldPreload = newConfig.preload !== undefined ? newConfig.preload : this.options.preload;
        if (shouldPreload) {
          this.loadElement(newConfig.url, newConfig.tag, newConfig.isModule, newConfig.importMap, newConfig.hooks);
        }
      }
    });
  }
  getElementConfig(tag) {
    return this.configs.find(config => config.tag === tag);
  }
  preload(tags) {
    let configs = this.configs;
    if (tags) {
      configs = this.configs.filter(config => tags.includes(config.tag));
    }
    configs.forEach(config => this.loadElement(config.url, config.tag, config.isModule, config.importMap, config.hooks));
  }
  async loadElement(url, tag, isModule, importMap, hooksConfig) {
    const config = this.getElementConfig(tag);
    isModule ??= config?.isModule ?? this.options.isModule;
    importMap ??= config?.importMap ?? this.options.importMap;
    if (ngDevMode && !tag) {
      throw new Error(`${LOG_PREFIX$2} - tag for '${url}' not found, the *axLazyElement has to be used on HTML element`);
    }
    if (!url) {
      if (ngDevMode && !config?.url && !importMap) {
        throw new Error(`${LOG_PREFIX$2} - url for <${tag}> not found`);
      } else if (importMap) {
        url = tag;
      } else {
        url = config.url;
      }
    }
    if (!this.#hasElement(url)) {
      const notifier = this.#addElement(url);
      const beforeLoadHook = hooksConfig?.beforeLoad ?? config?.hooks?.beforeLoad ?? this.options?.hooks?.beforeLoad;
      const afterLoadHook = hooksConfig?.afterLoad ?? config?.hooks?.afterLoad ?? this.options?.hooks?.afterLoad;
      if (importMap) {
        url = await this.#resolveImportMap(url);
      }
      const script = document.createElement('script');
      if (isModule) {
        script.type = 'module';
      }
      script.src = getPolicy()?.createScriptURL(url) ?? url;
      const onLoad = () => {
        if (afterLoadHook) {
          this.#handleHook(afterLoadHook, tag).then(notifier.resolve).catch(notifier.reject);
        } else {
          notifier.resolve();
        }
        cleanup();
      };
      const onError = error => {
        notifier.reject(error);
        cleanup();
        // Caretaker note: don't put it before the `reject` and `cleanup` since the user may have some
        // custom error handler that will re-throw the error through `throw error`. Hence the code won't
        // be executed, and the promise won't be rejected.
        this.#errorHandler.handleError(error);
      };
      // The `load` and `error` event listeners capture `this`. That's why they have to be removed manually.
      // Otherwise, the `LazyElementsLoaderService` is not going to be GC'd.
      function cleanup() {
        script.removeEventListener('load', onLoad);
        script.removeEventListener('error', onError);
      }
      script.addEventListener('load', onLoad, {
        signal: LazyElementsLoaderService.controller?.signal
      });
      script.addEventListener('error', onError, {
        signal: LazyElementsLoaderService.controller?.signal
      });
      if (beforeLoadHook) {
        this.#handleHook(beforeLoadHook, tag).then(() => document.body.appendChild(script)).catch(notifier.reject);
      } else {
        document.body.appendChild(script);
      }
    }
    return this.#registry.get(this.#stripUrlProtocol(url));
  }
  #addElement(url) {
    let notifier;
    this.#registry.set(this.#stripUrlProtocol(url), new Promise((resolve, reject) => notifier = {
      resolve,
      reject
    }));
    return notifier;
  }
  #hasElement(url) {
    return this.#registry.has(this.#stripUrlProtocol(url));
  }
  #stripUrlProtocol(url) {
    return url.replace(/https?:\/\//, '');
  }
  #handleHook(hook, tag) {
    try {
      return Promise.resolve(hook(tag));
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async #resolveImportMap(url) {
    const System = window.System;
    if (System) {
      await System.prepareImport();
      url = System.resolve(url);
    } else if (ngDevMode) {
      throw new Error(`${LOG_PREFIX$2} - importMap feature depends on SystemJS library to be globally loaded but none was found, thus '${url}' can't be resolved. You should either load SystemJS or remove the importMap flag.`);
    }
    return url;
  }
  static ɵfac = function LazyElementsLoaderService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LazyElementsLoaderService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: LazyElementsLoaderService,
    factory: LazyElementsLoaderService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LazyElementsLoaderService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, {
    ngOnDestroy: [{
      type: HostListener,
      args: ['unloaded']
    }]
  });
})();
const LOG_PREFIX$1 = '@angular-extensions/elements';
class LazyElementDirective {
  #platformId = inject(PLATFORM_ID);
  #destroyRef = inject(DestroyRef);
  #vcr = inject(ViewContainerRef);
  #cdr = inject(ChangeDetectorRef);
  #template = inject(TemplateRef);
  #elementsLoaderService = inject(LazyElementsLoaderService);
  url = null;
  loadingTemplateRef = null;
  errorTemplateRef = null;
  isModule; // eslint-disable-line @angular-eslint/no-input-rename
  importMap = false; // eslint-disable-line @angular-eslint/no-input-rename
  loadingSuccess; // eslint-disable-line @angular-eslint/no-input-rename
  // eslint-disable-next-line @angular-eslint/no-input-rename
  loadingError;
  #viewRef = null;
  #url$ = new BehaviorSubject(null);
  ngOnChanges(changes) {
    if (changes.url) {
      this.#url$.next(this.url);
    }
  }
  ngOnInit() {
    // There's no sense to execute the below logic on the Node.js side since the JavaScript
    // will not be loaded on the server-side (Angular will only append the script to body).
    // The `loadElement` promise will never be resolved, since it gets resolved when the `load` event is emitted.
    // `customElements` are also undefined on the Node.js side; thus, it will always render the error template.
    if (isPlatformServer(this.#platformId)) {
      return;
    }
    this.#setupUrlListener();
  }
  destroyEmbeddedView() {
    if (this.#viewRef && !this.#viewRef.destroyed) {
      this.#viewRef.detach();
      this.#viewRef.destroy();
      this.#viewRef = null;
    }
  }
  #setupUrlListener() {
    const tpl = this.#template;
    const elementTag = tpl._declarationTContainer ? tpl._declarationTContainer.tagName || tpl._declarationTContainer.value : tpl._def.element.#template.nodes[0].element.name;
    const elementConfig = this.#elementsLoaderService.getElementConfig(elementTag) || {};
    const options = this.#elementsLoaderService.options;
    const loadingComponent = elementConfig.loadingComponent || options.loadingComponent;
    this.#url$.pipe(
    // This is used to coalesce changes since the `url$` subject might emit multiple values initially, e.g.
    // `null` (initial value) and the url itself (when the `url` binding is provided).
    // The `animationFrameScheduler` is used to prevent the frame drop.
    debounceTime(0, animationFrameScheduler), switchMap(url => {
      if (this.loadingTemplateRef) {
        this.#vcr.createEmbeddedView(this.loadingTemplateRef);
      } else if (loadingComponent) {
        this.#vcr.createComponent(loadingComponent);
      }
      return from(this.#elementsLoaderService.loadElement(url, elementTag, this.isModule, this.importMap, elementConfig?.hooks)).pipe(catchError(error => {
        this.loadingError?.(error);
        this.#vcr.clear();
        const errorComponent = elementConfig.errorComponent || options.errorComponent;
        if (this.errorTemplateRef) {
          this.#vcr.createEmbeddedView(this.errorTemplateRef);
          this.#cdr.markForCheck();
        } else if (errorComponent) {
          this.#vcr.createComponent(errorComponent);
          this.#cdr.markForCheck();
        } else if (ngDevMode && !this.loadingError) {
          console.error(`${LOG_PREFIX$1} - Loading of element <${elementTag}> failed, please provide <ng-template #error>Loading failed...</ng-template> and reference it in *axLazyElement="errorTemplate: error" to display customized error message in place of element`);
        }
        return EMPTY;
      }));
    }), tap(() => this.loadingSuccess?.()), mergeMap(() => customElements.whenDefined(elementTag)), takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: () => {
        this.#vcr.clear();
        this.#viewRef = this.#vcr.createEmbeddedView(this.#template);
        this.#cdr.markForCheck();
      }
    });
  }
  static ɵfac = function LazyElementDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LazyElementDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: LazyElementDirective,
    selectors: [["", "axLazyElement", ""]],
    inputs: {
      url: [0, "axLazyElement", "url"],
      loadingTemplateRef: [0, "axLazyElementLoadingTemplate", "loadingTemplateRef"],
      errorTemplateRef: [0, "axLazyElementErrorTemplate", "errorTemplateRef"],
      isModule: [0, "axLazyElementModule", "isModule"],
      importMap: [0, "axLazyElementImportMap", "importMap"],
      loadingSuccess: [0, "axLazyElementLoadingSuccess", "loadingSuccess"],
      loadingError: [0, "axLazyElementLoadingError", "loadingError"]
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LazyElementDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[axLazyElement]'
    }]
  }], null, {
    url: [{
      type: Input,
      args: ['axLazyElement']
    }],
    loadingTemplateRef: [{
      type: Input,
      args: ['axLazyElementLoadingTemplate']
    }],
    errorTemplateRef: [{
      type: Input,
      args: ['axLazyElementErrorTemplate']
    }],
    isModule: [{
      type: Input,
      args: ['axLazyElementModule']
    }],
    importMap: [{
      type: Input,
      args: ['axLazyElementImportMap']
    }],
    loadingSuccess: [{
      type: Input,
      args: ['axLazyElementLoadingSuccess']
    }],
    loadingError: [{
      type: Input,
      args: ['axLazyElementLoadingError']
    }]
  });
})();
const LOG_PREFIX = '@angular-extensions/elements';
class LazyElementDynamicDirective {
  tag = null;
  url = null; // eslint-disable-line @angular-eslint/no-input-rename
  loadingTemplateRef = null;
  errorTemplateRef = null;
  isModule = false; // eslint-disable-line @angular-eslint/no-input-rename
  importMap = false; // eslint-disable-line @angular-eslint/no-input-rename
  loadingSuccess; // eslint-disable-line @angular-eslint/no-input-rename
  // eslint-disable-next-line @angular-eslint/no-input-rename
  loadingError;
  #viewRef = null;
  #destroyRef = inject(DestroyRef);
  #platformId = inject(PLATFORM_ID);
  #document = inject(DOCUMENT);
  #renderer = inject(Renderer2);
  #vcr = inject(ViewContainerRef);
  #cdr = inject(ChangeDetectorRef);
  #template = inject(TemplateRef);
  #elementsLoaderService = inject(LazyElementsLoaderService);
  ngOnInit() {
    // There's no sense to execute the below logic on the Node.js side since the JavaScript
    // will not be loaded on the server-side (Angular will only append the script to body).
    // The `loadElement` promise will never be resolved, since it gets resolved when the `load` event is emitted.
    // `customElements` are also undefined on the Node.js side; thus, it will always render the error template.
    if (isPlatformServer(this.#platformId)) {
      return;
    }
    if (ngDevMode) {
      if (!this.tag || this.tag.length === 0 || !this.tag.includes('-')) {
        throw new Error(`${LOG_PREFIX} - Valid tag has to be specified when using *axLazyElementDynamic directive (use *axLazyElementDynamic="'some-tag'"), got: "${this.tag}"`);
      }
    }
    const tag = this.tag;
    const elementConfig = this.#elementsLoaderService.getElementConfig(tag) || {};
    const options = this.#elementsLoaderService.options;
    const loadingComponent = elementConfig.loadingComponent || options.loadingComponent;
    if (this.loadingTemplateRef) {
      this.#vcr.createEmbeddedView(this.loadingTemplateRef);
    } else if (loadingComponent) {
      this.#vcr.createComponent(loadingComponent);
    }
    const loadElement$ = from(this.#elementsLoaderService.loadElement(this.url, tag, this.isModule, this.importMap, elementConfig?.hooks));
    loadElement$.pipe(mergeMap(() => customElements.whenDefined(tag)), takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: () => {
        this.loadingSuccess?.();
        this.#vcr.clear();
        const originalCreateElement = this.#renderer.createElement;
        this.#renderer.createElement = (name, namespace) => {
          if (name === 'ax-lazy-element') {
            name = tag;
          }
          return this.#document.createElement(name);
        };
        this.#viewRef = this.#vcr.createEmbeddedView(this.#template);
        this.#renderer.createElement = originalCreateElement;
        this.#cdr.markForCheck();
      },
      error: error => {
        this.loadingError?.(error);
        const errorComponent = elementConfig.errorComponent || options.errorComponent;
        this.#vcr.clear();
        if (this.errorTemplateRef) {
          this.#vcr.createEmbeddedView(this.errorTemplateRef);
          this.#cdr.markForCheck();
        } else if (errorComponent) {
          this.#vcr.createComponent(errorComponent);
          this.#cdr.markForCheck();
        } else if (ngDevMode && !this.loadingError) {
          console.error(`${LOG_PREFIX} - Loading of element <${this.tag}> failed, please provide <ng-template #error>Loading failed...</ng-template> and reference it in *axLazyElementDynamic="errorTemplate: error" to display customized error message in place of element\n\n`, error);
        }
      }
    });
  }
  destroyEmbeddedView() {
    if (this.#viewRef && !this.#viewRef.destroyed) {
      this.#viewRef.detach();
      this.#viewRef.destroy();
      this.#viewRef = null;
    }
  }
  static ɵfac = function LazyElementDynamicDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LazyElementDynamicDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: LazyElementDynamicDirective,
    selectors: [["", "axLazyElementDynamic", ""]],
    inputs: {
      tag: [0, "axLazyElementDynamic", "tag"],
      url: [0, "axLazyElementDynamicUrl", "url"],
      loadingTemplateRef: [0, "axLazyElementDynamicLoadingTemplate", "loadingTemplateRef"],
      errorTemplateRef: [0, "axLazyElementDynamicErrorTemplate", "errorTemplateRef"],
      isModule: [0, "axLazyElementDynamicModule", "isModule"],
      importMap: [0, "axLazyElementDynamicImportMap", "importMap"],
      loadingSuccess: [0, "axLazyElementLoadingSuccess", "loadingSuccess"],
      loadingError: [0, "axLazyElementLoadingError", "loadingError"]
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LazyElementDynamicDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[axLazyElementDynamic]'
    }]
  }], null, {
    tag: [{
      type: Input,
      args: ['axLazyElementDynamic']
    }],
    url: [{
      type: Input,
      args: ['axLazyElementDynamicUrl']
    }],
    loadingTemplateRef: [{
      type: Input,
      args: ['axLazyElementDynamicLoadingTemplate']
    }],
    errorTemplateRef: [{
      type: Input,
      args: ['axLazyElementDynamicErrorTemplate']
    }],
    isModule: [{
      type: Input,
      args: ['axLazyElementDynamicModule']
    }],
    importMap: [{
      type: Input,
      args: ['axLazyElementDynamicImportMap']
    }],
    loadingSuccess: [{
      type: Input,
      args: ['axLazyElementLoadingSuccess']
    }],
    loadingError: [{
      type: Input,
      args: ['axLazyElementLoadingError']
    }]
  });
})();
function createLazyElementRootGuard(rootOptions) {
  if (ngDevMode && rootOptions) {
    throw new TypeError(`LazyElementsModule.forRoot() called twice. Lazy feature modules should use LazyElementsModule.forFeature() instead.`);
  }
  return 'LazyElementsModule.forRoot() multiple execution guard';
}
class LazyElementsModule {
  static forRoot(options) {
    return {
      ngModule: LazyElementsModule,
      providers: [{
        provide: LAZY_ELEMENT_CONFIGS,
        useValue: options && options.elementConfigs ? options.elementConfigs : [],
        multi: true
      }, {
        provide: LAZY_ELEMENT_ROOT_OPTIONS,
        useValue: options.rootOptions ? options.rootOptions : {}
      }, {
        provide: LAZY_ELEMENT_ROOT_GUARD,
        useFactory: createLazyElementRootGuard,
        deps: [[LAZY_ELEMENT_ROOT_OPTIONS, new Optional(), new SkipSelf()]]
      }]
    };
  }
  static forFeature(options) {
    return {
      ngModule: LazyElementsModule,
      providers: [{
        provide: LAZY_ELEMENT_CONFIGS,
        useValue: options && options.elementConfigs ? options.elementConfigs : [],
        multi: true
      }]
    };
  }
  lazyElementsLoaderService = inject(LazyElementsLoaderService);
  elementConfigsMultiProvider = inject(LAZY_ELEMENT_CONFIGS, {
    optional: true
  });
  guard = inject(LAZY_ELEMENT_ROOT_GUARD, {
    optional: true
  });
  constructor() {
    if (this.elementConfigsMultiProvider && this.elementConfigsMultiProvider.length) {
      this.elementConfigsMultiProvider.filter(configs => configs.some(config => !config.isAdded)).forEach(configs => this.lazyElementsLoaderService.addConfigs(configs));
    }
  }
  static ɵfac = function LazyElementsModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LazyElementsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: LazyElementsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LazyElementsModule, [{
    type: NgModule,
    args: [{
      imports: [LazyElementDirective, LazyElementDynamicDirective],
      exports: [LazyElementDirective, LazyElementDynamicDirective]
    }]
  }], () => [], null);
})();
function createProvideLazyElementsGuard(rootOptions) {
  if (ngDevMode && rootOptions) {
    throw new TypeError(`provideAxLazyElements() called twice. Lazy feature modules should use provideAxLazyElementsConfigs() instead.`);
  }
  return 'provideAxLazyElements() multiple execution guard';
}
function provideAxLazyElements(options = {}) {
  return makeEnvironmentProviders([{
    provide: LAZY_ELEMENT_CONFIGS,
    useValue: options && options.elementConfigs ? options.elementConfigs : [],
    multi: true
  }, {
    provide: LAZY_ELEMENT_ROOT_OPTIONS,
    useValue: options.rootOptions ? options.rootOptions : {}
  }, {
    provide: LAZY_ELEMENT_ROOT_GUARD,
    useFactory: createProvideLazyElementsGuard,
    deps: [[LAZY_ELEMENT_ROOT_OPTIONS, new Optional(), new SkipSelf()]]
  }, {
    multi: true,
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      inject(LAZY_ELEMENT_ROOT_GUARD, {
        optional: true
      });
      const lazyElementsLoaderService = inject(LazyElementsLoaderService);
      const elementConfigsMultiProvider = inject(LAZY_ELEMENT_CONFIGS, {
        optional: true
      });
      if (elementConfigsMultiProvider && elementConfigsMultiProvider.length) {
        elementConfigsMultiProvider.filter(configs => configs.some(config => !config.isAdded)).forEach(configs => lazyElementsLoaderService.addConfigs(configs));
      }
    }
  }]);
}
function provideAxLazyElementsConfigs(configs = []) {
  return makeEnvironmentProviders([{
    provide: LAZY_ELEMENT_CONFIGS,
    useValue: configs && configs.length ? configs : [],
    multi: true
  }, {
    multi: true,
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      inject(LAZY_ELEMENT_ROOT_GUARD, {
        optional: true
      });
      const lazyElementsLoaderService = inject(LazyElementsLoaderService);
      const elementConfigsMultiProvider = inject(LAZY_ELEMENT_CONFIGS, {
        optional: true
      });
      if (elementConfigsMultiProvider && elementConfigsMultiProvider.length) {
        elementConfigsMultiProvider.filter(configs => configs.some(config => !config.isAdded)).forEach(configs => lazyElementsLoaderService.addConfigs(configs));
      }
    }
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { LAZY_ELEMENTS_REGISTRY, LAZY_ELEMENT_CONFIGS, LAZY_ELEMENT_ROOT_GUARD, LAZY_ELEMENT_ROOT_OPTIONS, LazyElementDirective, LazyElementDynamicDirective, LazyElementsLoaderService, LazyElementsModule, createLazyElementRootGuard, createProvideLazyElementsGuard, provideAxLazyElements, provideAxLazyElementsConfigs };
